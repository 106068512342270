import Runner from "./Runner";
import Navigation from "./Navigation";
import "./Header.css";

export default function Header() {
  return (
    <header>
      <div className="complex-banner">
        <img
          src="https://images.complex.com/complex/image/upload/v1715870103/Custom/topo-chico/complex-white.png"
          alt="complex logo"
        />
      </div>
      <Runner />
      <Navigation />
    </header>
  );
}
