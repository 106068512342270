import { useEffect, useRef } from "react";
import { track } from '@vercel/analytics';
import { gsap } from "gsap";
import useBubbleEffect from "../../utils/useBubbleEffect";
import "./FlavorSection.css";

export default function FlavorSection() {
  const MEDIA_URL = "https://images.complex.com/complex/image/upload/f_auto,q_auto/v1715869153/Custom/topo-chico/";
  const bubbleRef = useRef(null);
  useBubbleEffect(bubbleRef);
  
  useEffect(() => {
    // Create a timeline for stars
    const starsTimeline = gsap.timeline({ repeat: -1, yoyo: true, ease: "none" });
    starsTimeline.to("#flavor-star-1, #flavor-star-2, #flavor-star-3", {
      rotation: 360,
      duration: 8,
      ease: "none"
    });

    // Create a timeline for bubbles
    const bubblesTimeline = gsap.timeline({ repeat: -1, yoyo: true, ease: "power1.inOut" });
    bubblesTimeline.to("#flavor-bubble-1, #flavor-bubble-2, #flavor-bubble-3", {
      scale: 1.25,
      duration: 4,
    });
  }, []);

  return (
    <section id="flavor-section">
      <div className="flavor-elements">
        <img id="flavor-bubble-1" className="background-el" src={`${MEDIA_URL}/elmnt_ad_bubble_04.png`} alt="bubble 1" />
        <img id="flavor-bubble-2" className="background-el" src={`${MEDIA_URL}/elmnt_ad_bubble_01.png`} alt="bubble 2" />
        <img id="flavor-bubble-3" className="background-el" src={`${MEDIA_URL}/elmnt_ad_bubble_03.png`} alt="bubble 3" />
        <img id="flavor-star-1" className="background-el" src={`${MEDIA_URL}/elmnt_ad_star_01.png`} alt="star 1" />
        <img id="flavor-star-2" className="background-el" src={`${MEDIA_URL}/elmnt_ad_star_02.png`} alt="star 2" />
        <img id="flavor-star-3" className="background-el" src={`${MEDIA_URL}/elmnt_ad_star_03.png`} alt="star 3" />
        <img id="orange" className="background-el" src={`${MEDIA_URL}/elmnt_fruit_01.png`} alt="fruit 1" />
        <img id="lime" className="background-el" src={`${MEDIA_URL}/elmnt_fruit_02.png`} alt="fruit 2" />
        <img id="blueberry" className="background-el" src={`${MEDIA_URL}/elmnt_fruit_03.png`} alt="fruit 3" />
      </div>
      <div className="flavor-row">
        <div className="flavor-text">
          <img src="https://images.complex.com/complex/image/upload/f_auto,q_auto/v1715867957/Custom/topo-chico/lockup_flavours_01.png" alt="flavor that sparkles" />
        </div>
        <div className="cans-container">
          <img
            src="https://images.complex.com/complex/image/upload/f_auto,q_auto/v1715869092/Custom/topo-chico/can-group-2.png"
            alt="topo chico cans"
          />
        </div>
      </div>
      <div className="discover-text">
        <div className="discover-hed">
          <p>DISCOVER A NEW WAY</p>
          <p>TO SPARKLING WATER</p>
        </div>
        <p>
          Topo Chico Sabores are made with real fruit juice with a hint of
          herbal extracts, paired with the signature minerality & exceptional
          effervescence for an elevated taste on a healthier indulgence.
        </p>
      </div>
      <a
        href="https://www.coca-cola.com/us/en/brands/topo-chico?utm_source=OLV_p&utm_medium=Video&utm_campaign=GLtBM00066&utm_term=ComplexMicrosite&utm_content=video"
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className="hotSpot" ref={bubbleRef}>
        <button 
          id="topo-button" 
          onClick={() => track('ClickThrough')}
        >VISIT TOPOCHICO.COM</button>
        </div>
      </a>
    </section>
  );
}
