import { useEffect } from 'react'
import { gsap } from "gsap";
import Episode from "./Episode";
import "./HeroSection.css";

export default function HeroSection() {
  const MEDIA_URL = "https://images.complex.com/complex/image/upload/f_auto,q_auto/v1715869153/Custom/topo-chico";
  
  useEffect(() => {
    // Create a timeline for continuous rotation of stars
    const starsTimeline = gsap.timeline({ repeat: -1, yoyo: true, ease: "none" });
    starsTimeline.to("#star-1, #star-2, #star-3, #star-4, #red-star", {
      rotation: 360,
      duration: 6,
      ease: "none"
    });

    // Create a timeline for continuous scaling of bubbles
    const bubblesTimeline = gsap.timeline({ repeat: -1, yoyo: true, ease: "power1.inOut" });
    bubblesTimeline.to("#bubble-1, #bubble-2", {
      scale: 1.75,
      duration: 2.5,
    });
  }, []);

  return (
    <div id="hero-section">
      <div className="elements">
        <img id="swirl" className="background-el" src={`${MEDIA_URL}/elmnt_badge_01.png`} alt="background shape" />
        <img id="sunburst" className="background-el" src={`${MEDIA_URL}/elmnt_badge_02.png`} alt="background shape" />
        <img id="red-star" className="background-el" src={`${MEDIA_URL}/elmnt_badge_03.png`} alt="background shape" />
        <img id="cloud" className="background-el" src="https://images.complex.com/complex/image/upload/q_auto/v1717600061/Custom/topo-chico/cloud.png" alt="background shape" />
        <img id="bubble-1" className="background-el" src={`${MEDIA_URL}/elmnt_intro_bubble_01.png`} alt="bubble 1" />
        <img id="bubble-2" className="background-el" src={`${MEDIA_URL}/elmnt_intro_bubble_02-1.png`} alt="bubble 2" />
        <img id="star-1" className="background-el" src={`${MEDIA_URL}/elmnt_intro_star_01.png`} alt="star 1" />
        <img id="star-2" className="background-el" src={`${MEDIA_URL}/elmnt_intro_star_02.png`} alt="star 2" />
        <img id="star-3" className="background-el" src={`${MEDIA_URL}/elmnt_intro_star_03.png`} alt="star 3" />
        <img id="star-4" className="background-el" src={`${MEDIA_URL}/elmnt_intro_star_04.png`} alt="star 4" />
      </div>
      <div className="logo-container">
        <img
          src={`${MEDIA_URL}/saborestv-logo.png`}
          alt="Sabores TV Logo"
        />
      </div>
      <div id="episodes">
        <Episode
         epNumber="EP.02"
         videoSrc={`https://images.complex.com/complex/video/upload/q_auto/v1717772003/Custom/topo-chico/topo-sabores-music-16x9-FINAL.mp4`}
         frameSrc={`https://images.complex.com/complex/image/upload/f_auto,q_auto/v1717690640/Custom/topo-chico/tv-green-2.png`}
         description="Effervescence is music to our ears. Host Rob Anderson chats with musician Parris Fleming as he serenades us with “STARZ,” a hit from his upcoming EP. Get updates on gale-force flavor with weatherman Phoenix Storms and watch Rob discover Tangerine and Ginger effervescence in Sabores or SADbores."
         poster={`https://images.complex.com/complex/image/upload/f_auto,q_auto/v1717772153/Custom/topo-chico/music-poster.jpg`}
         />
        <Episode
          epNumber="EP.01"
          videoSrc={`https://images.complex.com/complex/video/upload/v1715872709/Custom/topo-chico/topo-sabores-food-16x9-FINAL_1.mp4`}
          frameSrc={`https://images.complex.com/complex/image/upload/f_auto,q_auto/v1717598131/Custom/topo-chico/TV_02.png`}
          description="Get ready for a flavor feast! Host Rob Anderson sits down with Atlanta chef Dayana Joseph and learns how to make her infamous Grilled Lobster with Scotch Bonnet Sauce. Discover Ms. Sheryl’s deals on Sabores Shopping and watch Rob taste the Lime and Mint difference in Sabores or SADbores."
          poster={`${MEDIA_URL}/Food_PR_10.jpg`}
        />
      </div>
    </div>
  );
}