import { Link } from 'react-router-dom'
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import "./Footer.css";

const deletionValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
});

export default function Footer() {
  const handleDeletionSubmit = (values, { setSubmitting, resetForm }) => {
    const endpoint = "https://formspree.io/f/mrgnvqpv";

    fetch(endpoint, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Deletion request submitted successfully:", data);
        alert("Your deletion request has been submitted successfully.");
        resetForm();
      })
      .catch((error) => {
        console.error("Deletion request submission error:", error);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <footer>
      <div className="footer-content">
        <div className="footer-logo">
          <img
            src="https://images.complex.com/complex/image/upload/v1715870103/Custom/topo-chico/complex-white.png"
            alt="complex logo white"
          />
        </div>
        <div className="data-request">
        <p>See official rules for sweepstakes <Link to="/terms" className='footer-link'>here</Link>.</p>
          <div className="data-request-text">
            <p>DO NOT SHARE MY PERSONAL INFORMATION</p>
            <p>Enter your email to request data deletion:</p>
          </div>
          <Formik
            initialValues={{ email: "" }}
            validationSchema={deletionValidationSchema}
            onSubmit={handleDeletionSubmit}
          >
            {({ isSubmitting }) => (
              <Form id="data-request-form">
                <Field 
                  type="email" 
                  name="email" 
                  placeholder="email address"
                  autoComplete="on" 
                />
                <ErrorMessage name="email" component="div" className="error" />
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="data-button"
                >
                  Submit
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <div className="legal-text">&copy; COMPLEX</div>
    </footer>
  );
}
