import './Runner.css'

export default function Runner() {

    return (
        <div className="Runner">
            <div className="tickerWrapper">
                <div className="tickerText">FOLLOW US @ TOPOCHICOUSA &nbsp; &nbsp; FOLLOW US @ TOPOCHICOUSA &nbsp; &nbsp; FOLLOW US @ TOPOCHICOUSA &nbsp; &nbsp;</div>
                <div className="tickerText">FOLLOW US @ TOPOCHICOUSA &nbsp; &nbsp; FOLLOW US @ TOPOCHICOUSA &nbsp; &nbsp; FOLLOW US @ TOPOCHICOUSA &nbsp; &nbsp;</div>
                <div className="tickerText">FOLLOW US @ TOPOCHICOUSA &nbsp; &nbsp; FOLLOW US @ TOPOCHICOUSA &nbsp; &nbsp; FOLLOW US @ TOPOCHICOUSA &nbsp; &nbsp;</div>
                <div className="tickerText">FOLLOW US @ TOPOCHICOUSA &nbsp; &nbsp; FOLLOW US @ TOPOCHICOUSA &nbsp; &nbsp; FOLLOW US @ TOPOCHICOUSA &nbsp; &nbsp;</div>
                <div className="tickerText">FOLLOW US @ TOPOCHICOUSA &nbsp; &nbsp; FOLLOW US @ TOPOCHICOUSA &nbsp; &nbsp; FOLLOW US @ TOPOCHICOUSA &nbsp; &nbsp;</div>
            </div>
        </div>
    )
}