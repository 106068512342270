import './TermsAndConditions.css'

export default function TermsAndConditions() {
  return (
    <div className="terms-and-conditions">
      <p className="center">
        <strong>
          <u>COMPLEX x TOPO CHICO SABORES PROMOTION</u>
        </strong>
      </p>
      <p className="center">
        <strong>OFFICIAL RULES</strong>
      </p>
      <p>
        <strong>
          NO PURCHASE NECESSARY TO ENTER OR WIN. MAKING A PURCHASE OR PAYMENT OF
          ANY KIND WILL NOT INCREASE YOUR CHANCE OF WINNING A PRIZE. VOID WHERE
          PROHIBITED BY LAW.{" "}
        </strong>
      </p>
      <p>
        <strong>
          YOUR ENTRY IN THIS PROMOTION MEANS THESE OFFICIAL RULES FORM A BINDING
          LEGAL CONTRACT, SO READ THEM CAREFULLY BEFORE PARTICIPATING. WITHOUT
          LIMITATION, THIS CONTRACT PROVIDES FOR YOUR INDEMNIFICATION OF THE
          SPONSORS AND OTHER PARTIES, THE WAIVER OF THE RIGHTS TO PARTICIPATE IN
          A CLASS ACTION OR A JURY TRIAL, AND A REQUIREMENT THAT MOST DISPUTES
          WILL BE SETTLED BY MANDATORY BINDING ARBITRATION.
        </strong>
      </p>
      <ol start="1">
        <li>
          <strong>Eligibility. </strong>
        </li>
      </ol>
      <p>
        The Complex x Topo Chico Sabores Promotion (the &ldquo;Promotion&rdquo;)
        is open only to legal residents of the 50 United States and the District
        of Colombia who, at the time of entry, are at least eighteen (18) years
        old and the age of majority in their state of legal residence. Void
        where prohibited by law. Directors, management, staff, and employees of
        Sponsor, or any of its parent(s), subsidiaries, affiliates, advertising
        agencies, suppliers, distributors, or retailers, or any other company or
        individual involved with the design, production, execution or
        distribution of the Promotion, and their immediate family (spouse,
        ex-spouse, de facto partner, parents and step parents, grandparents and
        step grandparents, siblings and step siblings, uncle, aunt, niece,
        nephew, brother, sister, step brother, step sister, first cousin and
        children and stepchildren) and household members (people who share the
        same residence at least three (3) months out of the year) of each such
        employee are not eligible. The Promotion is subject to all applicable
        federal, state, and local laws and regulations. Participation
        constitutes entrant&rsquo;s (&ldquo;Entrant&rdquo;) full and
        unconditional agreement to these Official Rules and Sponsor&rsquo;s
        decisions, which are final and binding in all matters related to the
        Promotion. Winning a Prize (defined below) is contingent upon selected
        Entrant fulfilling all requirements set forth herein.
      </p>
      <ol start="2">
        <li>
          <strong>Sponsors. </strong>
        </li>
      </ol>
      <p>
        Sponsors: Commerce Media Holdings LLC dba COMPLEX NTWRK, with an address
        at 7060 Hollywood Blvd, 2nd Floor, Los Angeles, CA 90028
        (&ldquo;Complex&rdquo;) and MediaCom Worldwide LLC
        (&ldquo;Agency&rdquo;), as agent for The Coca-Cola Company&rsquo;s North
        America Group (&ldquo;Advertiser&rdquo;) (Complex and Advertiser
        individually and collectively referred to herein as
        &ldquo;Sponsor(s)&rdquo;).
      </p>
      <ol start="3">
        <li>
          <strong>Sweepstakes Entry Periods. </strong>
        </li>
      </ol>
      <p>
        The Promotion is divided into two (2) separate sweepstakes (each a
        &ldquo;Sweepstakes&rdquo; and collectively, the &ldquo;Microsite
        Sweepstakes&rdquo;), with a specific entry period (&ldquo;Sweepstakes
        Entry Period&rdquo;) and prize pool (&ldquo;Prize Pool&rdquo;).
      </p>
      <ol type="a">
        <li className="indent">
          <u>Microsite Fridge Sweepstakes</u>: The Microsite Fridge Sweepstakes
          entry period starts on May 31<sup>st</sup>, 2024, at 2:00 PM Pacific
          Time (&ldquo;PT&rdquo;) and ends on June 15<sup>th</sup>, 2024, at
          2:00 PM PT (the &ldquo;Microsite Fridge Sweepstakes Entry
          Period&rdquo;).
        </li>
        <li className="indent">
          <u>Microsite Turntable Sweepstakes</u>
          <strong>: </strong>The Microsite Turntable Sweepstakes entry period
          starts on June 28<sup>th</sup>, 2024, at 2:00 PM Pacific Time
          (&ldquo;PT&rdquo;) and ends on July 14<sup>th</sup>, 2024, at 2:00 PM
          PT (the &ldquo;Microsite Fridge Sweepstakes Entry Period&rdquo;).
        </li>
      </ol>
      <p>
        Entries received outside the applicable Sweepstakes Entry Period will be
        void. Sponsor is the official timekeeper for this Promotion and each of
        the Sweepstakes.
      </p>
      <ol start="4">
        <li>
          <strong>How to Enter the Sweepstakes. </strong>
        </li>
      </ol>
      <ol type="a">
        <li className="indent">
          <u>Microsite Sweepstakes</u>: During the Microsite Sweepstakes Entry
          Period, to obtain one (1) entry in the Microsite Sweepstakes, (i)
          access the online entry form at sabores-tv.com (ii) provide your name,
          and email address and (iii) follow the prompts to submit your entry.
          There is a maximum of one (1) entry in the Microsite Sweepstakes per
          person during the Microsite Sweepstakes Entry Period, per Prize.
        </li>
      </ol>
      <p>
        When entering a Sweepstakes, you agree to receive promotional email
        communications from Sponsor, unless you opt-out at any time. You can
        opt-out by heading to sabores-tv.com and following the opt-out entry
        form at the footer of the page.
      </p>
      <p>
        All entries become the sole and exclusive property of Sponsor and will
        not be acknowledged or returned. Entries beyond the permitted number are
        void. Use of multiple email addresses or any automated system to enter
        is prohibited and will result in disqualification.&nbsp;
      </p>
      <p>
        If applicable, Sponsor may reject any entry in its sole discretion if
        the entry in whole or in part: contains any illegal or offensive
        activity, or is obscene, defamatory, likely to incite violence or
        illegal; includes any material in violation of any third-party&rsquo;s
        rights; contains material which is (or promotes activities which are)
        sexually explicit, obscene, pornographic, violent, self-mutilating or
        mutilating of animals, discriminatory (based upon race, sex, religion,
        natural origin, physical disability, sexual orientation or age); is
        threatening, profane or harassing; portrays Sponsor or any person or
        entity negatively; violates any third-party rights including but not
        limited to copyright, trademark, patent, contract, and/or publicity or
        privacy rights, of any other person or entity; includes directly or
        indirectly any trademark, logo, or intellectual property in any way
        unless owned exclusively by Entrant, and/or which is otherwise deemed to
        be inappropriate or demeaning in Sponsor&rsquo;s sole discretion. Entry
        must not attempt to duplicate any other entry or third-party works.
        Notwithstanding, Sponsor reserves the right in its sole discretion to
        disqualify any entry that is a duplicate or substantially similar to
        another entry and Sponsor may, in its sole discretion, disqualify any
        entry that violates these parameters and/or any other part of Official
        Rules.
      </p>
      <p>
        By submitting an entry, Entrant hereby grants to Sponsor, and any and
        all of Sponsor&rsquo;s affiliates, successors, assignees, and/or
        licensees, the non-exclusive, fully paid, worldwide license to use,
        publicly perform, stream, modify, display and disseminate the entry,
        including the video or image, in perpetuity. For clarity, Sponsor may
        change or modify the entry as they see fit.
      </p>
      <ol start="5">
        <li>
          <strong>Sweepstakes Winner Selection.</strong>
        </li>
      </ol>
      <p>
        On or about June 15<sup>th</sup>, 2024 for the Microsite Fridge
        Sweepstakes and July 14<sup>th</sup>, 2024 for the Microsite Turntable
        Sweepstakes, (the &ldquo;Drawing Date&rdquo;) Sponsor will select the
        applicable number of entries in each Sweepstakes in separate random
        drawings among all valid entries received during the applicable
        Sweepstakes Entry Period as potential Prize winner(s) (&ldquo;Potential
        Prize Winner(s)&rdquo;). Sponsor will contact the Potential Prize
        Winner(s) as follows:
      </p>
      <ol type="a">
        <li className="indent">
          <u>Microsite Fridge Sweepstakes</u>: Potential Prize Winners will be
          contacted by email using the email address the Potential Prize
          Winner(s) used to enter the Microsite Sweepstakes, on or about June 15
          <sup>th</sup>, 2024.
        </li>
        <li className="indent">
          <u>Microsite Turntable Sweepstakes</u>: Potential Prize Winners will
          be contacted by email using the email address the Potential Prize
          Winner(s) used to enter the Microsite Sweepstakes, on or about July 14
          <sup>th</sup>, 2024.
        </li>
      </ol>
      <p>
        Failure by Potential Prize Winner(s) to respond to the initial
        verification within 48 hours of notification may result in
        disqualification, and Sponsor may, at its discretion, select an
        alternate Potential Prize Winner(s) from all remaining valid entries in
        the applicable Sweepstakes. Odds of winning a Prize depends on the
        number of eligible entries received during the applicable Sweepstakes
        Entry Period.
      </p>
      <ol start="6">
        <li>
          <strong>Prizes.</strong>
        </li>
      </ol>
      <p>
        Each prize (&ldquo;Prize&rdquo;) shall be comprised of one (1) Topo
        Chico x Vandy The Pink product. Total approximate retail value
        (&ldquo;ARV&rdquo;) of each Prize: $1,000.
      </p>
      <ol type="a">
        <li className="indent">
          Topo Chico x Vandy The Pink Desk Fridge. Total ARV of the Desk Fridge:
          $1,000 USD.
        </li>
        <li className="indent">
          Topo Chico x Vandy The Pink Turntable. Total ARV of the Turntable:
          $1,000 USD.
        </li>
      </ol>
      <p>
        Total Prizes: There are two (2x) Prizes available in the Microsite
        Sweepstakes. Total ARV of all Prizes available in the Microsite
        Sweepstakes: $2,000.
      </p>
      <p>
        THE PRIZES ARE OFFERED AND PROVIDED "AS IS" WITH NO WARRANTY OR
        GUARANTEE BY SPONSOR OTHER THAN WHAT IS EXPRESSLY INCLUDED WTH THE
        PRIZE.
      </p>
      <p>
        All expenses and costs not expressly listed in these Official Rules are
        the sole responsibility of the verified Prize winner(s) (&ldquo;Verified
        Prize Winner(s)&rdquo;). Verified Prize Winner(s) will be responsible
        for all other expenses relating to the receipt and use of the Prize.
        Prizes are nontransferable and may not be substituted or redeemed for
        cash by Verified Prize Winner(s). Sponsor reserves the right to
        substitute a Prize, in whole or in part, for one of equal or greater
        value. All federal, state, and local taxes are solely the responsibility
        of Verified Prize Winner(s). Odds of winning a Prize depend on the
        number of valid and eligible entries received during the applicable
        Sweepstakes Entry Period.
      </p>
      <p>
        Sponsor is not responsible for any change of email address and/or
        mailing address or account information of Entrants, nor is Sponsor
        responsible for any lost or damaged mail.
      </p>
      <p>
        <u>Maximum Number of Prizes</u>: Maximum of one (1) Prize(s) per person
        per Sweepstakes.
      </p>
      <ol start="7">
        <li>
          <strong>Verification of Potential Prize Winner.</strong>
        </li>
      </ol>
      <p>
        POTENTIAL PRIZE WINNER&rsquo;S ELIGIBILITY IS SUBJECT TO VERIFICATION BY
        SPONSOR, WHOSE DECISIONS ARE FINAL AND BINDING IN ALL MATTERS RELATED TO
        THE PROMOTION. Any Potential Prize Winner must continue to comply with
        all terms and conditions of these Official Rules and winning is
        contingent upon fulfilling all requirements. Sponsor may run a
        background check on any Potential Prize Winner. The Potential Prize
        Winner may be required to execute and return to Sponsor for its receipt
        within five (5) business days of notification, an Affidavit of
        Eligibility, Release of Liability and Publicity Release (where permitted
        by law) (collectively, the &ldquo;Affidavit&rdquo;) in order to claim
        his/her Prize. Sponsor may, but is not obligated to, exercise its rights
        under such Affidavit. In the event: (a) Potential Prize Winner(s) cannot
        be reached for whatever reason after a reasonable effort has been
        exerted or the Potential Prize Winner(s) notification or Affidavit is
        returned as undeliverable; (b) Potential Prize Winner(s) declines or
        cannot accept, receive or use the Prize for any reason; (c) of
        noncompliance with the above or within any of the aforesaid time
        periods; (d) Potential Prize Winner(s) is found to be ineligible to
        enter the Promotion or receive the Prize; (e) Potential Prize Winner(s)
        cannot or does not comply with the Official Rules; or (f) Potential
        Prize Winner(s) fails to fulfill the Affidavit-related obligations, the
        Potential Prize Winner shall be disqualified from the applicable
        Sweepstakes and an alternate Potential Prize Winner may be selected, at
        Sponsor&rsquo;s sole discretion, from among the other eligible entries
        received. Sponsor reserves the right to modify the notification and
        Affidavit procedures in connection with the selection of alternate
        Potential Prize Winner, if any. Verified Prize Winner(s) will be solely
        responsible for all applicable federal, state and local taxes on
        Prize(s) and may receive an IRS Form 1099 for the retail value of the
        Prize and must provide Sponsor with a valid social security number if
        requested for tax reporting purposes.
      </p>
      <ol start="8">
        <li>
          <strong>Entry Conditions and Release.</strong>
        </li>
      </ol>
      <p>
        By entering, each Entrant agrees to: (a) comply with and be bound by
        these Official Rules and the decisions of the Sponsor and/or its
        designee(s) which are binding and final in all matters relating to this
        Promotion ; (b) release and hold harmless Sponsors, Instagram, Twitter,
        Discord, and their respective parent, subsidiary and affiliated
        companies, the Prize suppliers and any other organizations and/or
        persons responsible for sponsoring, fulfilling, administering,
        advertising or promoting the Promotion or the applicable Sweepstakes,
        and all of their respective past and present officers, directors,
        employees, agents and representatives (collectively, the &ldquo;Released
        Parties&rdquo;) from and against any and all claims, expenses and
        liability, including but not limited to negligence and damages of any
        kind to persons and property, including but not limited to invasion of
        privacy (under appropriation, intrusion, public disclosure of private
        facts, false light in the public eye or other legal theory), defamation,
        slander, libel, violation of right of publicity, infringement of
        trademark, copyright or other intellectual property rights, property
        damage, or death or personal injury arising out of or relating to a
        participant&rsquo;s entry, creation of an entry or submission of an
        entry, participation in the Promotion, acceptance or use or misuse of
        Prize (including any travel or activity related thereto) and/or the
        broadcast, exploitation or use of entry; and (c) indemnify, defend and
        hold harmless the Released Parties from and against any and all claims,
        expenses, and liabilities (including reasonable attorneys&rsquo; fees)
        arising out of or relating to an Entrant&rsquo;s participation in the
        Promotion and/or Entrant&rsquo;s acceptance, use or misuse of any Prize.
      </p>
      <p>
        The Released Parties are not responsible for: (a) any incorrect or
        inaccurate information, whether caused by Entrant, or programming
        associated with or used in the Promotion; (b) technical failures of any
        kind, including but not limited to malfunctions, interruptions, or
        disconnections in phone lines or network hardware or software; (c)
        unauthorized human intervention in any part of the entry process or the
        applicable Sweepstakes; (d) technical or human error, which may occur in
        the administration of the applicable Sweepstakes or the processing of
        entries; (e) any injury or damage to persons or property, which may be
        caused, directly or indirectly, in whole or in part, from
        Entrant&rsquo;s participation in the Promotion or receipt or use or
        misuse of any Prize; or (f) entries, prize claims or notifications that
        are lost, late, incomplete, illegible, unintelligible, damaged or
        otherwise not received by the intended recipient, in whole or in part,
        due to computer, human or technical error of any kind, including but not
        limited to, by reason of unauthorized human intervention, spam filters,
        privacy settings, inactive email accounts or an entrant&rsquo;s failure
        to monitor its email account. If for any reason an Entrant&rsquo;s entry
        is confirmed to have been erroneously deleted, lost or otherwise
        destroyed or corrupted, Entrant&rsquo;s sole remedy is another entry in
        the applicable Sweepstakes. No more than the stated number of Prizes
        will be awarded. If, for any reason, the Promotion or a specific
        Sweepstakes cannot be executed as planned, including, but not limited
        to, any governmental order, epidemic, pandemic, force majeure, social
        media mandate, printing, administrative or other error of any kind,
        transmission failure, infection by computer virus, bugs, tampering,
        unauthorized intervention, fraud, technical failures, or any other
        causes beyond the control of Sponsor that corrupt or affect the
        security, administration, fairness, integrity or proper conduct of the
        Promotion, or if the Promotion or a specific Sweepstakes is compromised
        or becomes technically corrupted in any way, electronically or
        otherwise, Sponsor reserves the right, in its sole discretion, to
        cancel, modify or terminate the Promotion or any specific Sweepstakes
        and, if terminated before the original end date, to select the winners
        from among all eligible, non-suspect entries received as of the
        date/time of termination.
      </p>
      <ol start="9">
        <li>
          <strong>Publicity.</strong>
        </li>
      </ol>
      <p>
        Except where prohibited, Entrants agree that participation in the
        Promotion constitutes Verified Prize Winners&rsquo; consent to
        Sponsor&rsquo;s and its agents&rsquo; use of each such winner&rsquo;s
        name, likeness, photograph, voice, opinions and/or hometown and state
        for promotional purposes in any media (whether now known or invented in
        the future), worldwide, without further limitation, restriction, notice,
        review, approval, payment or consideration but that Sponsor is not
        required to make such use.
      </p>
      <ol start="10">
        <li>
          <strong>General Conditions.</strong>
        </li>
      </ol>
      <p>
        Entrant&rsquo;s entry shall comply with Sponsor&rsquo;s and
        Complex&rsquo;s, and/or any third-party platform provider&rsquo;s terms
        of service/use and privacy policies, and shall not: (a) contain material
        which is (or promoting activities which are) sexually explicit, obscene,
        pornographic, violent, self-mutilation, discriminatory (based on race,
        sex, religion, natural origin, physical disability, sexual orientation
        or age), illegal (e.g. underage drinking, substance abuse, computer
        hacking, etc.), offensive, threatening, profane, or harassing; (b)
        contain content which would be derogatory and/or insulting of any
        others, including Sponsor and/or other persons or entities, products or
        services; (c) contain content which refers to and/or is complimentary of
        any competitors of Sponsor or which is complimentary of and/or refers to
        any product competitive with any product or service of Sponsor; (d)
        include personally identifiable information or any other information or
        indicia of any person other than Entrant and should the Entrant include
        personally identifiable information about him/herself in his/her entry,
        Entrant acknowledges and agrees that such information will be disclosed
        publicly and Entrant is solely responsible for any consequences thereof;
        and (e) contain material that violates or infringes another&rsquo;s
        rights, including but not limited to privacy, publicity or intellectual
        property rights, including copyright infringement. Sponsor reserves the
        right to cancel, suspend and/or modify the Promotion, the Sweepstakes,
        or any part of them, if any fraud, technical failures, or any other
        factor beyond Sponsor&rsquo;s reasonable control impairs the integrity
        or proper functioning of the applicable Sweepstakes, as determined by
        Sponsor in its sole discretion. Sponsor reserves the right in its sole
        discretion to disqualify any individual it finds to be tampering with
        the entry process or the operation of the applicable Sweepstakes or to
        be acting in violation of these Official Rules or any other promotion or
        in an unsportsmanlike or disruptive manner. Any attempt by any person to
        deliberately undermine the legitimate operation of the Promotion may be
        a violation of criminal and civil law and, should such an attempt be
        made, Sponsor reserves the right to seek damages from any such person to
        the fullest extent permitted by law. Sponsor&rsquo;s failure to enforce
        any term of these Official Rules shall not constitute a waiver of that
        provision.
      </p>
      <p>
        In the event of a dispute regarding the identity of any Entrant, the
        authorized account holder of the email address used to enter the
        applicable Sweepstakes will be deemed to be the Entrant. The
        &ldquo;authorized account holder&rdquo; is the natural person assigned
        an email address by an Internet access provider, online service provider
        or other organization responsible for assigning email addresses for the
        domain associated with the submitted address. Potential Prize Winner(s)
        of any Prize may be required to show proof of being the authorized
        account holder to be awarded the Prize. All entry information becomes
        the sole and exclusive property of Sponsor and will not be returned or
        cancelled.
      </p>
      <ol start="11">
        <li>
          <strong>Limitations of Liability. </strong>
        </li>
      </ol>
      <p>
        BY ENTERING THE PROMOTION, ENTRANT AGREES THAT, TO THE FULLEST EXTENT
        PERMITTED BY APPLICABLE LAW: (1) ANY AND ALL DISPUTES, CLAIMS AND CAUSES
        OF ACTION ARISING OUT OF OR CONNECTED WITH THE PROMOTION, OR ANY
        PRIZE(S) AWARDED WILL BE LIMITED TO ACTUAL, OUT-OF-POCKET COSTS INCURRED
        (IF ANY) NOT TO EXCEED TEN DOLLARS ($10.00), BUT IN NO EVENT WILL
        ATTORNEYS&rsquo; FEES BE AWARDED OR RECOVERABLE; AND (2) UNDER NO
        CIRCUMSTANCES WILL ANY ENTRANT BE PERMITTED TO OBTAIN ANY AWARD FOR, AND
        ENTRANT HEREBY KNOWINGLY AND EXPRESSLY WAIVES ALL RIGHTS TO SEEK,
        PUNITIVE, INCIDENTAL, CONSEQUENTIAL OR SPECIAL DAMAGES, LOST PROFITS
        AND/OR ANY OTHER DAMAGES AND/OR ANY RIGHTS TO HAVE DAMAGES MULTIPLIED OR
        OTHERWISE INCREASED.
      </p>
      <p>
        SOME JURISDICTIONS DO NOT ALLOW THE FOREGOING LIMITATIONS OR EXCLUSION
        OF LIABILITY, SO THE ABOVE MAY NOT APPLY TO YOU.
      </p>
      <p>
        <strong>
          <u>FOR CALIFORNIA ENTRANTS</u>
        </strong>
        : EACH ENTRANT UNDERSTANDS AND AGREES THAT ALL RIGHTS UNDER SECTION 1542
        OF THE CIVIL CODE OF CALIFORNIA AND ANY SIMILAR LAW OF ANY STATE OF THE
        UNITED STATES ARE HEREBY EXPRESSLY WAIVED BY HIM/HER AGAINST THE
        RELEASED PARTIES. SECTION 1542 READS AS FOLLOWS:
      </p>
      <p>
        &ldquo;<em>CERTAIN CLAIMS NOT AFFECTED BY A GENERAL RELEASE</em>. A
        GENERAL RELEASE DOES NOT EXTEND TO CLAIMS THAT THE CREDITOR OR RELEASING
        PARTY DOES NOT KNOW OR SUSPECT TO EXIST IN HIS OR HER FAVOR AT THE TIME
        OF EXECUTING THE RELEASE AND THAT, IF KNOWN BY HIM OR HER, WOULD HAVE
        MATERIALLY AFFECTED HIS OR HER SETTLEMENT WITH THE DEBTOR OR RELEASED
        PARTY.&rdquo;
      </p>
      <ol start="12">
        <li>
          <strong>
            BINDING ARBITRATION AGREEMENT AND CLASS ACTION WAIVER.{" "}
          </strong>
        </li>
      </ol>
      <p>
        Except where prohibited by law, as a condition of participating in this
        Promotion, each Entrant agrees as follows:
      </p>
      <p>
        <strong>Binding Arbitration Agreement.</strong> Except as otherwise
        stated below, any and all legal issue, claim or dispute arising out of
        or connected or related in any way to the Promotion, including but not
        limited to Prize awarded, shall be resolved through final, binding
        arbitration. Entrant specifically agrees that, except as otherwise
        provided herein, entry into the Promotion waives all rights to bring a
        lawsuit based on such claim(s) or dispute(s) and the right to have such
        lawsuit resolved by a judge or a jury.
      </p>
      <p>
        This Arbitration Agreement shall be governed by the Federal Arbitration
        Act, (9 U.S.C. &sect; 1 <em>et seq.</em>). Any arbitration will be
        commenced and administered by JAMS under the rules of JAMS, including
        JAMS Consumer Arbitration Minimum Standards [
        <u>https://www.jamsadr.com/consumer-minimum-standards/]</u>. If
        JAMS&rsquo; criteria are met by the nature of the dispute, the Entrant
        agrees that the arbitration shall be conducted under JAMS Streamlined
        Arbitration Rules &amp; Procedures [
        <a href="https://www.jamsadr.com/rules-streamlined-arbitration/">
          https://www.jamsadr.com/rules-streamlined-arbitration/
        </a>
        ]. Judgment upon any arbitration award may be entered in any court of
        appropriate jurisdiction.
      </p>
      <p>
        Each party will pay the fees for his/her or its own attorneys, subject
        to any remedies to which that party may later be entitled under
        applicable law. However, if Entrant is unable to pay JAMS&rsquo; costs,
        Sponsor will pay all arbitration fees and expenses. If JAMS is
        unavailable, unwilling, or otherwise unable to administer an arbitration
        in accordance with these rules, then another arbitration administrator
        will do, to be selected by agreement of the parties.
      </p>
      <p>
        <strong>
          Neither Entrant nor Sponsor shall be permitted to obtain awards, and
          all parties hereby waive all rights to claim, punitive, incidental or
          consequential damages, or any other damages, including
          attorneys&rsquo; fees, other than Entrant&rsquo;s actual out-of-pocket
          expenses (i.e., costs associated with participating in this
          Promotion). The parties further waive all rights to have damages
          multiplied or increased.
        </strong>
      </p>
      <p>
        The arbitrator has the same authority to award relief on an individual
        basis that a judge in a court of law would have. The award of the
        arbitrator is final and binding upon both you and us. Additionally, the
        arbitrator, and not any federal, state, or local court or agency, shall
        have the exclusive authority to resolve any dispute relating to the
        interpretation, applicability, enforceability, or formation of this
        Arbitration Agreement, except that this sentence shall not apply to the
        Class Action Waiver provisions described below. The arbitrator will
        render a decision in writing. A court of competent jurisdiction shall
        have the authority to enter judgment upon the arbitrator's
        decision/award. This Arbitration Agreement will survive the termination
        of your relationship with us.
      </p>
      <p>
        <strong>Excluded Disputes.</strong> Notwithstanding the binding
        arbitration requirement set forth in this section, Entrant may bring
        qualifying claims in small claims court so long as the matter remains in
        such court and advances only on an individual, non-class,
        non-representative basis.
      </p>
      <p>
        <strong>Class Action Waiver</strong>. To fullest extent permitted by
        applicable law, you and we agree to bring any claim or dispute, whether
        in arbitration, or court as permitted by these terms, ON AN INDIVIDUAL
        BASIS ONLY, AND NOT AS A CLASS ACTION OR COLLECTIVE ACTION. There shall
        be no right or authority for any claim or dispute to be brought, heard
        or arbitrated as a class or collective action ("Class Action Waiver").
        The arbitration will decide the rights and liabilities, if any, of you
        and us. The arbitration proceeding will not be consolidated with any
        other matters or joined with any other cases or parties. The arbitrator
        may award any remedy to which a party is entitled under applicable law,
        but remedies shall be limited to those that would be available to a
        party in their individual capacity.&nbsp;Regardless of anything else in
        this Arbitration Agreement and/or the applicable JAMS rules, the
        interpretation, applicability, enforceability, or formation of the Class
        Action Waiver may only be determined by a court and not an arbitrator.
        This Class-Action Waiver will survive the termination of your
        relationship with us.
      </p>
      <p>
        <strong>One-Year Time Limit to Raise Disputes or Claims</strong>. You
        agree that any dispute, claim or lawsuit, regardless of form, that may
        arise out of or related to the Promotion, must be filed within ONE (1)
        YEAR of the action, omission, event, or occurrence giving rise to the
        dispute, claim(s) or lawsuit. After the expiration of the one-year
        period, such dispute, claim(s) or lawsuit will be time-barred and
        prohibited, without regard to any longer period of time which may be
        provided by any period of limitation or pursuant to law or statute.
      </p>
      <p>
        <strong>Waiver of Jury Trial</strong>
        <strong>.&nbsp;</strong> BOTH ENTRANT AND SPONSOR HEREBY WAIVE ANY
        CONSTITUTIONAL AND STATUTORY RIGHTS TO SUE IN COURT AND HAVE A TRIAL IN
        FRONT OF A JUDGE OR A JURY, except as provided herein. We are instead
        mutually electing that all disputes, claims, or requests for relief
        shall be resolved by arbitration under this Arbitration Agreement,
        except as specified herein. An arbitrator can award on an individual
        basis the same damages and relief as a court and must follow this
        Agreement as a court would. However, there is no judge or jury in
        arbitration, and court review of an arbitration award is subject to very
        limited review.
      </p>
      <p>
        <strong>Location of Arbitration.</strong> Entrant may choose to have the
        arbitration conducted by video conference technology such as zoom, or
        based on written submissions. Otherwise, the location of the arbitration
        proceeding shall take place in the city or state where Entrant resides,
        unless each party to the arbitration agrees otherwise, or at another
        mutually agreed upon location.&nbsp;
      </p>
      <ol start="13">
        <li>
          <strong>Governing Law. </strong>
        </li>
      </ol>
      <p>
        These Official Rules and any disputes between us shall be governed by
        the laws of the State of New York without giving effect to any choice of
        law or conflict of law rules (whether of the State of New York or any
        other jurisdiction), which would cause the application of the laws of
        any jurisdiction other than the State of New York.
      </p>
      <ol start="14">
        <li>
          <strong>Severability.</strong>
        </li>
      </ol>
      <p>
        In the event any provision of these Official Rules is determined to be
        void or unenforceable, such determination shall not affect the remainder
        of these terms, which shall continue to be in force.
      </p>
      <ol start="15">
        <li>
          <strong>Entrant&rsquo;s Personal Information. </strong>
        </li>
      </ol>
      <p>
        When registering for an applicable Sweepstakes, you expressly agree to
        receive promotional communications from Sponsor. Information collected
        from Entrants is subject to the Sponsor&rsquo;s Privacy Policy, which is
        available at{" "}
        <a href="https://www.complex.com/privacy">
          https://www.complex.com/privacy
        </a>
        . If you are selected as a Potential or Verified Prize Winner, your
        name, city and state may also be included in a publicly available
        Winners' List.
      </p>
      <ol start="16">
        <li>
          <strong>Sweepstakes&rsquo; Results.</strong>
        </li>
      </ol>
      <p>
        For the Sweepstakes results, send a hand-printed, self-addressed,
        stamped envelope to: &ldquo;Winner&rsquo;s List&rdquo; c/o Complex x
        Topo Chico Sabores Promotion, 7060 Hollywood Blvd., 2<sup>nd</sup>{" "}
        Floor, Los Angeles, CA 90028. Requests for the Winner&rsquo;s List must
        be received within 60 calendar days after the end of the applicable
        Sweepstakes Entry Period, and must identify the specific Sweepstakes for
        which a Winner&rsquo;s List is requested.
      </p>
      <p>
        <strong>Questions?</strong> Please email legal@thentwrk.com
      </p>
    </div>
  );
}
