import "./ProductDetails.css";

export default function ProductDetails( { 
  imgSrc, 
  openPopup, 
  title, 
  description,
  tooltipText,
  tooltipSection,
  outOfStock
 }) {

  const createDescriptionWithTip = () => {
    if (!tooltipSection || !tooltipText) return description

    const parts = description.split(tooltipSection)
    return (
      <>
        {parts[0]}
          <span
            className="tooltip-trigger" 
            data-tooltip={tooltipText}
            >
              {tooltipSection}
          </span>
        {parts[1]}
      </>
    )
  } 

  return (
    <div className="product-container">
      <div className="product-image">
        <img src={imgSrc} alt="product" />
      </div>
      <div className="product-text">
        <h5>{title}</h5>
        <p className="product-description">
         {createDescriptionWithTip()}
        </p>
        <button 
          className={`enter-button ${outOfStock ? 'out-of-stock' : ''}`}
          onClick={openPopup} 
          disabled={outOfStock}>{outOfStock ? 'OUT OF STOCK!' : 'ENTER TO WIN'}</button>
      </div>
    </div>
  );
}
