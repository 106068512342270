import { useState } from "react";
import { Navigation, Pagination, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import "./Lookbook.css";

const MEDIA_URL = "https://images.complex.com/complex/image/upload/q_auto/v1717154754/Custom/topo-chico";
const MEDIA_TT_URL = "https://images.complex.com/complex/image/upload/q_auto";

const images = [
  `${MEDIA_TT_URL}/v1719496138/Custom/topo-chico/TOPO-TURNTABLE-LOOKBOOK-01.jpg`,
  `${MEDIA_TT_URL}/v1719496138/Custom/topo-chico/TOPO-TURNTABLE-LOOKBOOK-02.jpg`,
  `${MEDIA_TT_URL}/v1719496138/Custom/topo-chico/TOPO-TURNTABLE-LOOKBOOK-03.gif`,
  `${MEDIA_TT_URL}/v1719496138/Custom/topo-chico/TOPO-TURNTABLE-LOOKBOOK-04.gif`,
  `${MEDIA_URL}/02-TOPO-LOOKBOOK-IMAGE.jpg`,
  `${MEDIA_URL}/03-TOPO-LOOKBOOK-IMAGE.jpg`,
  `${MEDIA_URL}/04-TOPO-LOOKBOOK-ROTATION.gif`,
];

export default function Lookbook() {
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [lightboxImage, setLightboxImage] = useState("");

  const handleImageClick = (image) => {
    setLightboxImage(image);
    setLightboxOpen(true);
  };

  const closeLightbox = () => {
    setLightboxOpen(false);
    setLightboxImage("");
  };

  return (
    <section id="lookbook-section">
      <h2 className="section-title">LOOKBOOK</h2>
      <div className="swiper-container">
        <div className="swiper-wrapper">
          <Swiper
            modules={[Navigation, Pagination, A11y]}
            spaceBetween={30}
            slidesPerView={1}
            navigation={{
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            }}
            pagination={{
              el: ".swiper-pagination",
              clickable: true,
            }}
            breakpoints={{
              768: {
                slidesPerView: 3,
              },
            }}
          >
            {images.map((image, index) => (
              <SwiperSlide key={index}>
                <img
                  src={image}
                  alt={`product ${index + 1}`}
                  onClick={() => handleImageClick(image)}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className="swiper-navigation">
          <img
            src="https://images.complex.com/complex/image/upload/f_auto,q_auto/v1716318803/Custom/topo-chico/swiper-prev.png"
            alt="previous slide"
            className="swiper-button-prev"
          />
          <div className="swiper-pagination"></div>
          <img
            src="https://images.complex.com/complex/image/upload/f_auto,q_auto/v1716318803/Custom/topo-chico/swiper-next.png"
            alt="previous slide"
            className="swiper-button-next"
          />
        </div>
      </div>
      {lightboxOpen && (
        <div className="lightbox" onClick={closeLightbox}>
          <div
            className="lightbox-content"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="lb-image-container">
              <img
                src={lightboxImage}
                alt="lightbox"
                className="lightbox-image"
              />
              <div className="close" onClick={closeLightbox}>
                &times;
              </div>
            </div>
          </div>
        </div>
      )}
    </section>
  );
}
