import ProductDetails from "./ProductDetails";

import "./MerchSection.css";

export default function MerchSection({ openPopup }) {
  const MEDIA_EL_URL =
    "https://images.complex.com/complex/image/upload/f_auto,q_auto/v1715869153/Custom/topo-chico";

  const MEDIA_LB_URL =
    "https://images.complex.com/complex/image/upload/f_auto,q_auto";

  const MEDIA_TT_URL =
    "https://images.complex.com/complex/image/upload/f_auto,q_auto";

  return (
    <section id="merch-section">
      <img
        id="merch-sunburst"
        src={`${MEDIA_EL_URL}/elmnt_badge_02.png`}
        alt="background shape"
      />
      <img
        id="merch-swirl"
        src={`${MEDIA_EL_URL}/elmnt_badge_01.png`}
        alt="background shape"
      />
      <h2 className="section-title">MERCH DROPS</h2>
      <div className="merch-container">
        <div className="merch-row">
          <ProductDetails
            title="LIMITED EDITION TURNTABLE"
            imgSrc={`${MEDIA_TT_URL}/v1719496284/Custom/topo-chico/FRONT_ANGLED.png
                `}
            description=
              "In honor of the music-themed Episode 2 of SaboresTV, Vandy the Pink is back for another groovy new drop! This limited edition yellow turntable celebrates the intersection of music and art, featuring exclusive Vandy artwork and a Sabores flair. Accompanied by a Topo Chico Sabores can in hand, this turntable will liven up any space and produce endless beats all summer long!"
            tooltipSection="limited edition yellow turntable"
            tooltipText="This product is a customized item (the “Custom Turntable”) that in its current state as sold on our secondary resale platform was not created or designed by NTWRK nor Topo Chico. This Custom Turntable product has been created, customized, adapted and/or reconstructed or re-imagined solely by Vandy the Pink. Any product names, logos, brand names or other trademarks, trade dress or intellectual property featured or referred to on the Custom Turntable, in this show promoting the sale of the Custom Turntable, and on the product show page are the property of their respective trademark holders and such identification is nominal only to identify the original genuine product from which this new Custom Turntable was created. Neither NTWRK nor Topo Chico is an official dealer of this product but only a secondary resale platform for the Custom Turntable product."
            outOfStock={true}
            openPopup={openPopup}
          />
        </div>
        <div className="merch-row reverse">
          <ProductDetails
            title="LIMITED EDITION FRIDGE"
            imgSrc={`${MEDIA_LB_URL}/v1717085023/Custom/topo-chico/lookbook-fridge-merch.png
            `}
            description={
              "To celebrate Episode 1 of SaboresTV, we partnered with Vandy the Pink to bring you a limited edition fridge featuring exclusive Vandy artwork. This vibrant yellow fridge combines the iconic style of Topo Chico Sabores with the unique artistic flair of Vandy the Pink. Designed specifically for Topo Chico Sabores, it has ample storage for 12 cans to sit conveniently on your desk or table and is a must-have for fans of both brands and those who appreciate functional art."
            }
            outOfStock={true}
            openPopup={openPopup}
          />
        </div>
      </div>
    </section>
  );
}
