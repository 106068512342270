// import { useState } from 'react'
import Lookbook from "./Lookbook";
import HeroSection from "./HeroSection";
import MerchSection from './MerchSection';
import FlavorSection from "./FlavorSection";
import WaveSeparator from "./WaveSeparator";

import "./MainContent.css";

export default function Home() {
  
  return (
    <main id="main-content">
      <HeroSection />
      <WaveSeparator />     
      <Lookbook />
      <WaveSeparator waveColor='var(--sabores-yellow)' bgColor='var(--orange-wave)'/>
      <MerchSection /> 
      <WaveSeparator waveColor='var(--sabores-yellow)' bgColor='var(--sabores-red)'/>
      <FlavorSection />
    </main>
  );
}
