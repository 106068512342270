import { useEffect, useRef } from 'react';

const useBubbleEffect = (ref, maxBubbles = 4, delay = 200) => {
  // Use a ref to track whether we're currently waiting to create a new bubble
  const waitingForNextBubble = useRef(false);

  useEffect(() => {
    const handleMouseMove = (e) => {
      // Only create a new bubble if we're not already waiting to create one
      if (!waitingForNextBubble.current) {
        const activeBubbles = document.querySelectorAll('.bubble').length;
        if (activeBubbles < maxBubbles) {
          // Set the flag to true to indicate we're waiting to create another bubble
          waitingForNextBubble.current = true;

          // Use setTimeout to introduce the delay
          setTimeout(() => {
            const bubble = document.createElement('div');
            bubble.className = 'bubble';
            document.body.appendChild(bubble);
            const innerBubble = document.createElement('span')
            bubble.appendChild(innerBubble)
            bubble.style.left = `${e.pageX - 20}px`; // Adjust to center the bubble on the cursor
            bubble.style.top = `${e.pageY - 20}px`;

            // Automatically remove the bubble after the animation ends
            bubble.addEventListener('animationend', () => {
              bubble.remove();
            });

            // After the delay, reset the flag so another bubble can be created
            waitingForNextBubble.current = false;
          }, delay);
        }
      }
    };

    const targetElement = ref.current;
    if (targetElement) {
      targetElement.addEventListener('mousemove', handleMouseMove);

      return () => {
        targetElement.removeEventListener('mousemove', handleMouseMove);
        // Clean up by removing all bubbles when the component unmounts or the effect cleanup runs
        document.querySelectorAll('.bubble').forEach(bubble => bubble.remove());
      };
    }
  }, [ref, maxBubbles, delay]); // Dependency array

  // No return statement needed as we're directly manipulating the DOM
};

export default useBubbleEffect;

