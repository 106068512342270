import "./Episode.css";

export default function Episode({
  epNumber,
  videoSrc,
  frameSrc,
  description,
  poster,
}) {
  return (
    <div className="episode-container">
        <div className="tv">
          <div className="video-container">
            <video
              controls
              playsInline
              className="tv-video"
              poster={poster}
            >
              <source src={videoSrc} type="video/mp4" />
            </video>
            <img src={frameSrc} alt="retro tv outline" className="tv-frame" />
          </div>
          <div className="episode-description">
            <p>{epNumber}</p>
            <p>{description}</p>
          </div>
        </div>
    </div>
  )
}
