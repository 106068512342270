import "./Navigation.css";

export default function Navigation() {
  return (
    <nav id="navigation">
      <div className="header-icon left">
        <img
          src="https://images.complex.com/complex/image/upload/v1715869173/Custom/topo-chico/stars-and-circle-icon.png"
          alt="stars and circles icon"
        />
      </div>
      <ul className="nav-links">
        <li><a href="#episodes">TV</a></li>
        <li><a href="#lookbook-section">LOOKBOOK</a></li>
        <li><a href="#merch-section">MERCH</a></li>
        <li><a href="#flavor-section">ABOUT</a></li>
      </ul>
      <div className="header-icon right">
        <img
          src="https://images.complex.com/complex/image/upload/v1715869160/Custom/topo-chico/world-tv-icon.png"
          alt="world tv icon"
        />
      </div>
    </nav>
  );
}
